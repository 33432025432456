<template>
	<PageLayout :title="$t('deposit.title')">
		<form
			id="payasiaRedir"
			:action="`https://payment.pa-sys.com/app/page/${merchantId}`"
			method="post"
			name="payment"
			acceptcharset="utf-8"
		>
			<input id="amount" type="hidden" name="amount" />
			<input id="currency" type="hidden" name="currency" />
			<input id="customerCountry" type="hidden" name="customer_country" value="MS" />
			<input id="customerEmail" type="hidden" name="customer_email" />
			<input id="customerFirstName" type="hidden" name="customer_first_name" />
			<input id="customerIp" type="hidden" name="customer_ip" />
			<input id="customerLastName" type="hidden" name="customer_last_name" />
			<input id="customerPhone" type="hidden" name="customer_phone" />
			<input id="merchantReference" type="hidden" name="merchant_reference" />
			<input type="hidden" name="network" value="DirectDebit" />
			<input id="returnUrl" type="hidden" name="return_url" />
		</form>
		<div class="cashier-box cashier-box--deposit">
			<payment-logo :custom-logo="`${method}.png`" />
			<div class="small-width overflow-visible">
				<payment-wallet />
			</div>

			<div class="line-separator-wrapper">
				<div class="line-separator" />
			</div>

			<amount-buttons
				:buttons-value="currentMethod.predefinedDepositAmounts"
				:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
				:currency="currency"
				:form-errors="formErrors['amount']"
				:current-method="currentMethod"
				:overridden-min-amount="minAmount"
				@set-button-value="setButtonValue"
				@input-amount-value-validation="setFormValidation"
			/>

			<button type="button" class="button primary-button deposit center" :disabled="!form.validated" @click="pay()">
				{{ $t('deposit.top-up') }}
			</button>

			<div v-if="loading" class="loader-wrapper">
				<loader />
			</div>
		</div>
	</PageLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';

const loader = () => import('../../../../shared/loader/Loader.vue');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'PayasiaMyr',
	components: {
		PageLayout,
		loader,
		paymentLogo,
		amountButtons,
		paymentWallet,
	},
	data() {
		return {
			form: {
				amount: null,
				promocode: null,
				validated: true,
			},
			method: null,
			merchantId: null,
			currency: 'USD',
			minAmount: null,
			formErrors: {
				amount: null,
			},
			loading: true,
			paymentDone: false,
			walletId: null,
		};
	},
	computed: {
		...mapGetters(['isEmbedded', 'getUser']),
		currentMethod() {
			return this.$store.getters.getCurrentMethod('payasia_myr');
		},
	},
	async mounted() {
		const splitPath = this.$route.fullPath.split('/');
		const method = splitPath[splitPath.length - 1];
		this.method = method;
		await this.getConfig(this.method);
		await this.getUserWallet();
		this.loading = false;
	},
	methods: {
		async getUserWallet() {
			try {
				const { id } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.walletId = id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
		setPromoValue(event) {
			this.form.promocode = event;
		},
		setFormValidation(event) {
			this.form.validated = event;
		},
		async getConfig() {
			const response = await apiClient.payasiaGetConfig('payasia_myr');

			if (response.status === 200) {
				this.form.amount = response.data.config.preselectedValue;
				this.buttonValues = response.data.config.buttonValues;
				this.currency = response.data.config.currency;
				this.minAmount = response.data.config.buttonValues[0];
				this.merchantId = response.data.config.merchantId;
			} else {
				throw new Error('Invalid response from get config');
			}
		},
		async createTransaction(method, amount, currency, params) {
			const response = await apiClient.payasiaCreateTransaction(this.walletId, method, amount, currency, params);

			if (response.status === 201) {
				return response.data;
			}
			throw new Error('Invalid response from create transaction');
		},
		async pay() {
			this.loading = true;

			try {
				const user = this.getUser;
				document.getElementById('customerEmail').value = user.email;
				document.getElementById('customerFirstName').value = user.firstname;
				document.getElementById('customerLastName').value = user.lastname;
				document.getElementById('customerPhone').value = user.mobilenumber;
				document.getElementById('customerCountry').value = user.country;
				document.getElementById('amount').value = this.form.amount;
				document.getElementById('currency').value = this.currency;

				document.getElementById('returnUrl').value = `${process.env.VUE_APP_URL}/payment/payasia/webhook`;

				const form = document.getElementById('payasiaRedir');
				const formData = new FormData(form);
				const params = new URLSearchParams(formData);

				const result = await this.createTransaction(this.method, this.form.amount, this.currency, params.toString());

				document.getElementById('merchantReference').value = result.transactionId;
				const input = document.createElement('input');
				input.setAttribute('type', 'hidden');
				input.setAttribute('name', 'sign');
				input.setAttribute('value', result.sign);
				form.appendChild(input);

				if (this.isEmbedded) {
					const walletId = this.$route.params.walletId;

					form.setAttribute('target', '_blank');
					this.$router.push({
						path: `/payments/deposit/${walletId}/confirmation`,
						query: { status: 'external' },
					});
				}

				form.submit();
			} catch (e) {
				const walletId = this.$route.params.walletId;

				this.$router.push({
					path: `/payments/deposit/${walletId}/confirmation`,
					query: { status: 'error' },
				});
				console.error('Payment failed', e);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
<style scoped>
#perfectmoneyRedir {
	visibility: hidden;
}
</style>
